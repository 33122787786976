<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Remarks</p>
            </div>
            <div class="column">
              <div class="field">
                <div class="control is-pulled-right">
                  <div class="is-pulled-right">
                    <a class="button is-small is-success tooltip"
                      data-tooltip="Add Remarks"
                      @click="toggleRemarkModal()">
                      <span class="icon">
                        <i class="mdi mdi-18px mdi-plus" />
                      </span>
                      <span>Remarks</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="filter.pageIndex"
            :page-size="filter.pageSize"
            :total-rows="remarkTotalRows"
            :is-loading="isTableLoading"
            :sort-column="filter.sortColumn"
            :sort-order="filter.sortOrder"
            :show-header="false"
            @pageChanged="onPageChange"
            @sort="sort">
            <tr v-for="(remark, index) in pagedRemarks"
              :key="remark.remarkId"
              @click="highlightSelected(index, $event)">
              <td>
                <!-- <div class="is-pulled-left">
                </div> -->
                <div class="is-pulled-left ml-1">
                  <div v-show="editRemarkRowNumber === -1 || editRemarkRowNumber !== index">{{ remark.remarks }}</div>
                  <textarea v-if="editRemarkRowNumber === index"
                    @blur="editRemarkRowNumber = -1"
                    class="textarea"
                    v-model="remark.remarks"
                    rows="3"
                    v-focus-inserted />
                </div>
                <div class="is-clearfix" />
                <div class="is-flex is-align-items-center is-size-7">
                  <user-profile-icon v-if="remark.firstName || remark.displayName"
                    :is-text-profile="true"
                    :first-name="remark.firstName"
                    :last-name="remark.lastName"
                    :display-name="remark.displayName"
                    :width="20"
                    :height="20"
                    class="pr-1" />
                  <span class="is-italic">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </div>
              </td>
              <td class="is-content-width">
                <a class="button is-danger is-small is-inverted"
                  @click="setPinRemarkOrder(remark)">
                  <span class="icon is-medium">
                    <i class="mdi mdi-22px"
                      :class="[remark.pinRemarkOrder !== null ? 'mdi-pin' : 'mdi-pin-outline']" />
                  </span>
                </a>
                <a v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator"
                  class="button is-primary is-small is-inverted"
                  @mousedown="editRemark(index)">
                  <span class="icon is-medium">
                    <i class="mdi mdi-24px"
                      :class="[editRemarkRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
                  </span>
                </a>
                <a v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator"
                  class="button is-danger is-small is-inverted"
                  @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                  <span class="icon is-medium">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </a>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </article>
      </div>
      <div v-show="innerValue.dueInNotes.length !== 0"
        class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Due In Notes</p>
            </div>
          </div>
          <div class="field">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="dueInFilter.pageIndex"
              :page-size="dueInFilter.pageSize"
              :total-rows="dueInNotesTotalRows"
              :is-loading="isTableLoading"
              :sort-column="dueInFilter.sortColumn"
              :sort-order="dueInFilter.sortOrder"
              :show-header="false"
              @pageChanged="onDueInNotePageChange"
              @sort="sort">
              <tr v-for="(remark, index) in pagedDueInNotes"
                :key="remark.remarkId"
                @click="highlightSelected(index, $event)">
                <td>
                  <div class="is-pulled-left ml-1">
                    <div v-show="editDueInNoteRowNumber === -1 || editDueInNoteRowNumber !== index">{{ remark.remarks }}</div>
                    <textarea v-if="editDueInNoteRowNumber === index"
                      @blur="editDueInNoteRowNumber = -1"
                      class="textarea"
                      v-model="remark.remarks"
                      rows="3"
                      v-focus-inserted />
                  </div>
                  <div class="is-clearfix" />
                  <div class="is-flex is-align-items-center is-size-7">
                    <user-profile-icon v-if="remark.firstName || remark.displayName"
                      :is-text-profile="true"
                      :first-name="remark.firstName"
                      :last-name="remark.lastName"
                      :display-name="remark.displayName"
                      :width="20"
                      :height="20"
                      class="pr-1" />
                    <span class="is-italic">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </div>
                </td>
                <td v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator"
                  class="is-content-width">
                  <a class="button is-primary is-small is-inverted"
                    @click="editDueInNote(index)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-24px"
                        :class="[editDueInNoteRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
                    </span>
                  </a>
                  <a class="button is-danger is-small is-inverted"
                    @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </div>
        </article>
      </div>
      <div v-show="innerValue.dueOutNotes.length !== 0"
        class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Due Out Notes</p>
            </div>
          </div>
          <div class="field">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="dueOutFilter.pageIndex"
              :page-size="dueOutFilter.pageSize"
              :total-rows="dueOutNotesTotalRows"
              :is-loading="isTableLoading"
              :sort-column="dueOutFilter.sortColumn"
              :sort-order="dueOutFilter.sortOrder"
              :show-header="false"
              @pageChanged="onDueOutNotePageChange"
              @sort="sort">
              <tr v-for="(remark, index) in pagedDueOutNotes"
                :key="remark.remarkId"
                @click="highlightSelected(index, $event)">
                <td>
                  <div class="is-pulled-left ml-1">
                    <div v-show="editDueOutNoteRowNumber === -1 || editDueOutNoteRowNumber !== index">{{ remark.remarks }}</div>
                    <textarea v-if="editDueOutNoteRowNumber === index"
                      @blur="editDueOutNoteRowNumber = -1"
                      class="textarea"
                      v-model="remark.remarks"
                      rows="3"
                      v-focus-inserted />
                  </div>
                  <div class="is-clearfix" />
                  <div class="is-flex is-align-items-center is-size-7">
                    <user-profile-icon v-if="remark.firstName || remark.displayName"
                      :is-text-profile="true"
                      :first-name="remark.firstName"
                      :last-name="remark.lastName"
                      :display-name="remark.displayName"
                      :width="20"
                      :height="20"
                      class="pr-1" />
                    <span class="is-italic">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </div>
                </td>
                <td v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator"
                  class="is-content-width">
                  <a class="button is-primary is-small is-inverted"
                    @click="editDueOutNote(index)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-24px"
                        :class="[editDueOutNoteRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
                    </span>
                  </a>
                  <a class="button is-danger is-small is-inverted"
                    @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Printed Remarks</p>
            </div>
            <div class="column">
              <div class="field">
                <div class="control is-pulled-right">
                  <div class="is-pulled-right">
                    <a class="button is-small is-success tooltip"
                      data-tooltip="Add Remarks"
                      @click="toggleRemarkModal()">
                      <span class="icon">
                        <i class="mdi mdi-18px mdi-plus" />
                      </span>
                      <span>Remarks</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="printedFilter.pageIndex"
              :page-size="printedFilter.pageSize"
              :total-rows="printedRemarkTotalRows"
              :is-loading="isTableLoading"
              :sort-column="printedFilter.sortColumn"
              :sort-order="printedFilter.sortOrder"
              :show-header="false"
              @pageChanged="onPrintedRemarksPageChange"
              @sort="sort">
              <tr v-for="(remark, index) in pagedPrintedRemarks"
                :key="remark.remarkId">
                <td class="is-remark">
                  <div v-show="editPrintedRemarkRowNumber === -1 || editPrintedRemarkRowNumber !== index">{{ remark.remarks }}</div>
                  <textarea v-if="editPrintedRemarkRowNumber === index"
                    @blur="editPrintedRemarkRowNumber = -1"
                    class="textarea"
                    v-model="remark.remarks"
                    rows="3"
                    v-focus-inserted />
                  <div class="is-flex is-align-items-center is-size-7">
                    <user-profile-icon v-if="remark.firstName || remark.displayName"
                      :is-text-profile="true"
                      :first-name="remark.firstName"
                      :last-name="remark.lastName"
                      :display-name="remark.displayName"
                      :width="20"
                      :height="20"
                      class="pr-1" />
                    <span class="is-italic">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </div>
                </td>
                <td v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator"
                  class="has-text-centered is-content-width has-vertical-middle">
                  <a class="button is-primary is-small is-inverted"
                    @click="editPrintedRemark(index)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-24px"
                        :class="[editPrintedRemarkRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
                    </span>
                  </a>
                  <a class="button is-danger is-small is-inverted"
                    @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </div>
        </article>
      </div>
      <div v-if="showJobNoteRemarks"
        class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Job Remarks</p>
            </div>
            <div class="column">
              <div class="field">
                <div class="control is-pulled-right">
                  <div class="is-pulled-right">
                    <a class="button is-small is-success tooltip"
                      data-tooltip="Add Remarks"
                      @click="toggleRemarkModal()">
                      <span class="icon">
                        <i class="mdi mdi-18px mdi-plus" />
                      </span>
                      <span>Remarks</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="jobFilter.pageIndex"
              :page-size="jobFilter.pageSize"
              :total-rows="jobRemarkTotalRows"
              :is-loading="isTableLoading"
              :sort-column="jobFilter.sortColumn"
              :sort-order="jobFilter.sortOrder"
              :show-header="false"
              @pageChanged="onJobNoteRemarksPageChange"
              @sort="sort">
              <tr v-for="(remark, index) in pagedJobNoteRemarks"
                :key="remark.remarkId">
                <td class="is-remark">
                  <div v-show="editJobNoteRemarkRowNumber === -1 || editJobNoteRemarkRowNumber !== index">{{ remark.remarks }}</div>
                  <textarea v-if="editJobNoteRemarkRowNumber === index"
                    @blur="editJobNoteRemarkRowNumber = -1"
                    class="textarea"
                    v-model="remark.remarks"
                    rows="3"
                    v-focus-inserted />
                  <div class="is-flex is-align-items-center is-size-7">
                    <user-profile-icon v-if="remark.firstName || remark.displayName"
                      :is-text-profile="true"
                      :first-name="remark.firstName"
                      :last-name="remark.lastName"
                      :display-name="remark.displayName"
                      :width="20"
                      :height="20"
                      class="pr-1" />
                    <span class="is-italic">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </div>
                </td>
                <td v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator"
                  class="has-text-centered is-content-width has-vertical-middle">
                  <a class="button is-primary is-small is-inverted"
                    @click="editJobNoteRemark(index)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-24px"
                        :class="[editJobNoteRemarkRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
                    </span>
                  </a>
                  <a class="button is-danger is-small is-inverted"
                    @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Other Remarks</p>
            </div>
            <div class="column">
              <div class="field">
                <div class="control is-pulled-right">
                  <div class="is-pulled-right">
                    <div class="select">
                      <select v-model="otherRemarksIdFilter">
                        <option :value="0">All</option>
                        <option v-for="(propValue, prop) in otherRemarkTypes"
                          :key="propValue"
                          :value="propValue">{{ startCase(prop) }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="otherFilter.pageIndex"
            :page-size="otherFilter.pageSize"
            :total-rows="otherRemarkTotalRows"
            :is-loading="isTableLoading"
            :sort-column="otherFilter.sortColumn"
            :sort-order="otherFilter.sortOrder"
            :show-header="false"
            @pageChanged="onOtherRemarksPageChange"
            @sort="sort">
            <tr v-for="(remark, index) in pagedOtherRemarks"
              :key="remark.remarkId">
              <td class="is-remark">
                <div v-html="remark.remarks.replaceAll('\n', '<br>')" />
                <div class="is-flex is-align-items-center is-size-7">
                  <span class="is-italic">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </div>
              </td>
              <td v-if="$user.info.isSupportUser || $user.info.isCustomerAdministrator"
                class="has-text-centered is-content-width has-vertical-middle">
                <a class="button is-danger is-small is-inverted"
                  @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
                  <span class="icon is-medium">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </a>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <label class="label">Incident Date</label>
          <div class="field has-addons">
            <v-date-picker v-model="selectedIncidentNotesDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Incident Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <label class="label">Notes</label>
            <div class="control">
              <textarea class="textarea"
                v-model="incidentNotesText"
                placeholder="" />
            </div>
          </div>
        </article>
      </div>
    </div>
    <remark-modal v-if="value && isRemarkModalActive"
      :show-remark="true"
      :show-printed-remark="true"
      :show-job-note-remark="true"
      :show-recurring-remark="true"
      :active.sync="isRemarkModalActive"
      @add="addRemarks"
      @cancel="toggleRemarkModal()">
      <p slot="text-title">Add Quote Remarks</p>
    </remark-modal>
  </div>
</template>

<script>
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { RemarkModal } from '@/components/BulmaModal'
import { ExternalSettingTypes, RemarkTypes } from '@/enums'
import { RemarkColumns } from './columns'
import BulmaTable from '@/components/BulmaTable'
import RemarkService from '@/services/RemarkService'
import { FocusInserted } from '@/components/directives'
import UserProfileIcon from '@/components/UserProfileIcon/UserProfileIcon'
import { RemarkModel } from '@/classes/viewmodels'
import _startCase from 'lodash.startcase'
import { CompanySettingService } from '@/services'

export default {
  name: 'QuoteRemarks',
  directives: {
    FocusInserted
  },
  components: {
    RemarkModal,
    BulmaTable,
    UserProfileIcon
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: null,
      isRemarkModalActive: false,
      incidentNotesText: '',
      // incidentNotesDate: null,
      selectedIncidentNotesDate: null,
      selectedRow: null,
      isTableLoading: false,
      filter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 15
      },
      printedFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 6
      },
      jobFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 6
      },
      otherFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 6
      },
      dueInFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 4
      },
      dueOutFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 4
      },
      editRemarkRowNumber: -1,
      editPrintedRemarkRowNumber: -1,
      editJobNoteRemarkRowNumber: -1,
      editDueInNoteRowNumber: -1,
      editDueOutNoteRowNumber: -1,
      otherRemarkTypes: {
        //subset or RemarkTypes.js
        ORMSystemRemark: 19,
        PNETSystemRemark: 20,
        OtherSystemRemark: 21,
        ORMMessageRemark: 22,
        PNETMessageRemark: 23
      },
      otherRemarksIdFilter: 0,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      showJobNoteRemarks: false
    }
  },
  computed: {
    columns() {
      return RemarkColumns
    },
    remarkTypes() {
      return RemarkTypes
    },
    remarkTotalRows() {
      return this.innerValue.remarks.filter((r) => r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted).length
    },
    printedRemarkTotalRows() {
      return this.innerValue.printedRemarks.filter((r) => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted).length
    },
    jobRemarkTotalRows() {
      return this.innerValue.jobNoteRemarks.filter((r) => r.remarkTypeId === RemarkTypes.JobNote && !r.isDeleted).length
    },
    otherRemarkTotalRows() {
      return this.innerValue.otherRemarks.filter((r) => !r.isDeleted).length
    },
    dueInNotesTotalRows() {
      return this.innerValue.dueInNotes.filter((r) => !r.isDeleted).length
    },
    dueOutNotesTotalRows() {
      return this.innerValue.dueOutNotes.filter((r) => !r.isDeleted).length
    },
    pagedRemarks() {
      return this.activeRemarks.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    },
    pagedPrintedRemarks() {
      return this.activePrintedRemarks.slice(
        (this.printedFilter.pageIndex - 1) * this.printedFilter.pageSize,
        this.printedFilter.pageIndex * this.printedFilter.pageSize
      )
    },
    pagedJobNoteRemarks() {
      return this.activeJobNoteRemarks.slice(
        (this.jobFilter.pageIndex - 1) * this.jobFilter.pageSize,
        this.jobFilter.pageIndex * this.jobFilter.pageSize
      )
    },
    pagedOtherRemarks() {
      return this.activeOtherRemarks.slice((this.otherFilter.pageIndex - 1) * this.otherFilter.pageSize, this.otherFilter.pageIndex * this.otherFilter.pageSize)
    },
    pagedDueInNotes() {
      return this.activeDueInNotes.slice((this.dueInFilter.pageIndex - 1) * this.dueInFilter.pageSize, this.dueInFilter.pageIndex * this.dueInFilter.pageSize)
    },
    pagedDueOutNotes() {
      return this.activeDueOutNotes.slice((this.dueOutFilter.pageIndex - 1) * this.dueOutFilter.pageSize, this.dueOutFilter.pageIndex * this.dueOutFilter.pageSize)
    },
    activeRemarks() {
      const pinnedActiveRemarks = this.innerValue.remarks.filter(
        r => r.pinRemarkOrder !== null && r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted)
      const unpinnedActiveRemarks = this.innerValue.remarks.filter(
        r => r.pinRemarkOrder == null && r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted)
      pinnedActiveRemarks.sort(function (a, b) {
        return a.pinRemarkOrder - b.pinRemarkOrder
      })
      unpinnedActiveRemarks.sort(function (a, b) {
        return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
      })
      return [...pinnedActiveRemarks, ...unpinnedActiveRemarks]
    },
    activePrintedRemarks() {
      return this.innerValue.printedRemarks.filter((r) => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted)
    },
    activeJobNoteRemarks() {
      return this.innerValue.jobNoteRemarks.filter(
        (r) => r.remarkTypeId === RemarkTypes.JobNote && !r.isDeleted).sort((a, b) => {
        return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
      })
    },
    activeOtherRemarks() {
      if (!this.otherRemarksIdFilter) {
        return this.innerValue.otherRemarks.filter((r) => !r.isDeleted)
      } else {
        return this.innerValue.otherRemarks.filter((r) => !r.isDeleted && r.remarkTypeId === this.otherRemarksIdFilter)
      }
    },
    activeDueInNotes() {
      return this.innerValue.dueInNotes.filter((r) => r.remarkTypeId === RemarkTypes.DueInNote && !r.isDeleted)
    },
    activeDueOutNotes() {
      return this.innerValue.dueOutNotes.filter((r) => r.remarkTypeId === RemarkTypes.DueOutNote && !r.isDeleted)
    },
    incidentNotesRemark() {
      return this.innerValue.incidentNotes
    },
    incidentNotesData() {
      // return `${this.incidentNotesText}${this.incidentNotesDate}`
      return `${this.incidentNotesText}${this.selectedIncidentNotesDate}`
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = newVal
      },
      deep: true
    },
    incidentNotesData: {
      handler: async function (newVal, oldVal) {
        if (newVal) {
          if (this.innerValue.incidentNotes === undefined || !this.innerValue.incidentNotes) {
            this.innerValue.incidentNotes = await RemarkService.getNewEntity()
            this.innerValue.incidentNotes.remarkTypeId = RemarkTypes.INCNOT
            this.innerValue.incidentNotes.assetId = this.innerValue.quoteId
          }
          this.innerValue.incidentNotes.remarks = this.incidentNotesText
          // this.innerValue.incidentNotes.remarkDateTime = this.incidentNotesDate.toISOString().split('.')[0] + 'Z'
          this.innerValue.incidentNotes.remarkDateTime = this.selectedIncidentNotesDate.toISOString().split('.')[0] + 'Z'
        }
      }
    }
  },
  async created() {
    this.innerValue = this.value
    if (this.incidentNotesRemark) {
      this.incidentNotesText = this.incidentNotesRemark.remarks
      if (this.incidentNotesRemark.remarkDateTime) {
        // this.incidentNotesDate = new Date(`${this.incidentNotesRemark.remarkDateTime}`)
        this.selectedIncidentNotesDate = new Date(`${this.incidentNotesRemark.remarkDateTime}`)
      }
    }
    await this.getPlanningPlusActiveStatus()
  },
  mounted() {},
  methods: {
    async getPlanningPlusActiveStatus() {
      const externalSetting = await CompanySettingService.getCompanyExternalSettings()
      const [planningPlusExternalSetting] = externalSetting.filter( i => i.settingName == ExternalSettingTypes.SETTING_PLANNINGPLUS)
      this.showJobNoteRemarks = parseInt(planningPlusExternalSetting.property5) // Active property
    },
    setPinRemarkOrder(remark) {
      const selectedRemark = this.innerValue.remarks.find(r => r.remarkId == remark.remarkId)
      const pinnedRemarks = this.innerValue.remarks.filter(
        r => r.pinRemarkOrder !== null && r.remarkId !== remark.remarkId)
      if (selectedRemark.pinRemarkOrder == null) {
        selectedRemark.pinRemarkOrder = 0
        for (let remark of pinnedRemarks) {
          remark.pinRemarkOrder += 1
        }
      } else {
        const selectedPinRemarkOrder = selectedRemark.pinRemarkOrder
        selectedRemark.pinRemarkOrder = null
        for (let remark of pinnedRemarks) {
          if (remark.pinRemarkOrder > selectedPinRemarkOrder) {
            remark.pinRemarkOrder -= 1
          }
        }
      }
    },
    addRemarks(remark, printedRemark, jobNoteRemark) {
      if (remark) {
        const newRemark = new RemarkModel(
          this.innerValue.quoteId,
          RemarkTypes.Remark,
          remark,
          this.$userInfo.firstName,
          this.$userInfo.lastName,
          this.$userInfo.displayName
        )
        this.innerValue.remarks.splice(this.innerValue.remarks.length, 1, newRemark)
        this.innerValue.remarks.sort(function (a, b) {
          return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
        })
      }
      if (printedRemark) {
        const newPrintedRemark = new RemarkModel(
          this.innerValue.quoteId,
          RemarkTypes.PrintedRemark,
          printedRemark,
          this.$userInfo.firstName,
          this.$userInfo.lastName,
          this.$userInfo.displayName
        )
        this.innerValue.printedRemarks.splice(this.innerValue.printedRemarks.length, 1, newPrintedRemark)
        this.innerValue.printedRemarks.sort(function (a, b) {
          return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
        })
      }
      if (jobNoteRemark) {
        const newJobNoteRemark = new RemarkModel(
          this.innerValue.quoteId,
          RemarkTypes.JobNote,
          jobNoteRemark,
          this.$userInfo.firstName,
          this.$userInfo.lastName,
          this.$userInfo.displayName
        )
        this.innerValue.jobNoteRemarks.splice(this.innerValue.jobNoteRemarks.length, 1, newJobNoteRemark)
        this.innerValue.jobNoteRemarks.sort(function (a, b) {
          return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
        })
      }
      this.toggleRemarkModal()
    },
    toggleRemarkModal() {
      this.isRemarkModalActive = !this.isRemarkModalActive
    },
    deleteRemark(index, remarkId, remarkType) {
      let deletedRemark
      console.log('remarkType: ', remarkType)
      if (remarkType === RemarkTypes.Remark) {
        deletedRemark = this.activeRemarks.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.remarks.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.remarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.PrintedRemark) {
        deletedRemark = this.activePrintedRemarks.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.printedRemarks.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.printedRemarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.JobNote) {
        deletedRemark = this.activeJobNoteRemarks.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.printedRemarks.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.jobNoteRemarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.DueInNote) {
        deletedRemark = this.activeDueInNotes.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.dueInNotes.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.dueInNotes.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.DueOutNote) {
        deletedRemark = this.activeDueOutNotes.find((r) => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.dueOutNotes.findIndex((r) => r.remarkId === deletedRemark.remarkId)
          this.innerValue.dueOutNotes.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else {
        deletedRemark = this.innerValue.otherRemarks.find((r) => r.remarkId == remarkId)
        deletedRemark.isDeleted = true
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    onPrintedRemarksPageChange(pageIndex) {
      this.printedFilter.pageIndex = pageIndex
    },
    onJobNoteRemarksPageChange(pageIndex) {
      this.jobFilter.pageIndex = pageIndex
    },
    onOtherRemarksPageChange(pageIndex) {
      this.otherFilter.pageIndex = pageIndex
    },
    onDueInNotePageChange(pageIndex) {
      this.dueInFilter.pageIndex = pageIndex
    },
    onDueOutNotePageChange(pageIndex) {
      this.dueOutFilter.pageIndex = pageIndex
    },
    sort(name, order) {},
    onSelectIncidentNotesDate(date, dateNow) {
      // this.innerValue.incidentNotes.remarkDateTime = dateNow.toISOString().split('.')[0] + 'Z'
    },
    editRemark(row) {
      this.editRemarkRowNumber !== row ? (this.editRemarkRowNumber = row) : (this.editRemarkRowNumber = -1)
      this.editPrintedRemarkRowNumber = -1
    },
    editPrintedRemark(row) {
      this.editPrintedRemarkRowNumber !== row ? (this.editPrintedRemarkRowNumber = row) : (this.editPrintedRemarkRowNumber = -1)
      this.editRemarkRowNumber = -1
    },
    editJobNoteRemark(row) {
      this.editJobNoteRemarkRowNumber !== row ? (this.editJobNoteRemarkRowNumber = row) : (this.editJobNoteRemarkRowNumber = -1)
    },
    editDueInNote(row) {
      this.editDueInNoteRowNumber !== row ? (this.editDueInNoteRowNumber = row) : (this.editDueInNoteRowNumber = -1)
      this.editDueOutNoteRowNumber = -1
    },
    editDueOutNote(row) {
      this.editDueOutNoteRowNumber !== row ? (this.editDueOutNoteRowNumber = row) : (this.editDueOutNoteRowNumber = -1)
      this.editDueInNoteRowNumber = -1
    },
    camelCaseToStartCase(str) {
      return str.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1').replace(/^./, (s) => s.toUpperCase())
    },
    startCase(str) {
      return _startCase(str)
    }
  }
}
</script>

<style lang="scss" scoped>
.small-font {
  font-size: 10px;
}
</style>